import { Box, Button, Typography } from '@mui/material';

import { Layout } from '~/components/Meta/Layout';
import { HOME_PATH } from '~/constants/routes';

function FourOhFour() {
  return (
    <Layout meta="404" title="404">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={2}
        justifyContent="center"
        width="100%"
      >
        <Typography component="h1" variant="h3">
          Page Not Found
        </Typography>
        <Button href={HOME_PATH} variant="outlined">
          <Typography component="span" variant="body1">
            Back to Home
          </Typography>
        </Button>
      </Box>
    </Layout>
  );
}

export default FourOhFour;
